export default{
    install(Vue){
        Vue.prototype.$acc = function (num) {
            if(num>100){
                var regexp = /\B(?=(\d{3})+(?!\d))/g;
                return num.toString().replace(regexp, ',');
            }else{
                return num
            }
        }
    }
}