<template>
<div class="navbar">
    <ul class="flex-between-center w-100">
        <li v-for="(item,index) in cate" :key="index" class="w-100 text-center">
            <router-link :to="`/${$i18n.locale}${item.link}`" class="d-flex flex-column">
                <i class="fal icon" :class="item.icon"></i>
                <small class="title">{{item.name}}</small>
            </router-link>
        </li>
        <li class="w-100 text-center">
            <!-- 팝업 표출 -->
            <a href="javascript:void(0)" class="d-flex flex-column" @click="NavProfilePpFn()">
                <i class="fal fa-user-circle icon"></i>
                <small class="title">{{$t('sidebar.24')}}</small>
            </a>
        </li>
        <li class="w-100 text-center">
            <!-- 팝업 표출 -->
            <a href="javascript:void(0)" class="d-flex flex-column" @click="NavPpFn()">
                <i class="fal fa-sitemap icon"></i>
                <small class="title">{{$t('sidebar.5')}}</small>
            </a>
        </li>
    </ul>
    <div class="modal" tabindex="-1" v-if="navProfilePp" @click="NavProfilePpFn()">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <span class="ms-auto p-2 px-3 top-0 end-0 zindex-100" @click="NavProfilePpFn()">
                    <i class="fal fa-times fa-lg"></i>
                </span>
                <div class="modal-body pt-0">
                    <div class="d-flex flex-column gap-2">
                        <router-link :to="`/${this.$i18n.locale}/profile`" class="btn btn-outline-primary d-flex flex-column">
                            <span>{{$t("sidebar.24")}}</span>
                            <span class="sub">{{$t("sidebar.25")}}</span>
                        </router-link>
                        <router-link :to="`/${this.$i18n.locale}/changePw`" class="btn btn-outline-primary d-flex flex-column">
                            <span>{{$t("sidebar.24")}}</span>
                            <span class="sub">{{$t("sidebar.26")}}</span>
                        </router-link>
                        <router-link :to="`/${this.$i18n.locale}/changePin`" class="btn btn-outline-primary d-flex flex-column">
                            <span>{{$t("sidebar.24")}}</span>
                            <span class="sub">{{$t("sidebar.27")}}</span>
                        </router-link>
                        <router-link :to="`/${this.$i18n.locale}/otp`" class="btn btn-outline-primary d-flex flex-column">
                            <span>{{$t("sidebar.24")}}</span>
                            <span class="sub">{{$t("sidebar.28")}}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" v-if="navPp" @click="NavPpFn()">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <span class="ms-auto p-2 px-3 top-0 end-0 zindex-100" @click="NavPpFn()">
                    <i class="fal fa-times fa-lg"></i>
                </span>
                <div class="modal-body pt-0">
                    <div class="d-flex flex-column gap-2">
                        <router-link :to="`/${this.$i18n.locale}/ReferralTree`" class="btn btn-outline-primary d-flex flex-column">
                            <span>{{$t("dashboard.10")}}</span>
                            <span class="sub">{{$t("dashboard.11")}}</span>
                        </router-link>
                        <router-link :to="`/${this.$i18n.locale}/SupportTree`" class="btn btn-outline-primary d-flex flex-column">
                            <span>{{$t("dashboard.12")}}</span>
                            <span class="sub">{{$t("dashboard.13")}}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            navProfilePp:false,
            navPp:false,
            cate: [
                {
                    link: '/Membership',
                    icon: 'far fa-cubes',
                    name: this.$t('sidebar.9'),
                },
                {
                    link: '/AssetsHistory',
                    icon: 'fa-clock',
                    name: this.$t('sidebar.17'),
                },
                {
                    link: '/CoinList',
                    icon: 'fa-wallet',
                    name: this.$t('sidebar.29'),
                },
                
            ],
        }
    },
    methods:{
        NavPpFn(){
            this.navPp = !this.navPp;
        },
        NavProfilePpFn(){
            this.navProfilePp = !this.navProfilePp;
        }
    }
}
</script>
<style scoped>
.theme01 .navbar{
    background-color: #2d2b33;
    color: #eee;
}
.theme02 .navbar,
.theme03 .navbar{
    background-color: #fff;
    color: #666;
}
.navbar li>a{
    color: inherit;
}
.navbar{
    position: fixed;
    left: auto;
    bottom: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0 -2px 8px rgba(31, 31, 31, 0.1);
    padding: 1em 0;
    max-width: 768px;
}
.navbar .icon{
    font-size: 20px;
    padding-bottom: 3px;
}
.navbar .title{
    font-size: 9px;
    line-height: 1
}
.sub{
    font-size: 11px;
}
</style>