import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "./axios.js"
import addCommaPlugin from './assets/js/addComma.js'
import VueTelInput from 'vue-tel-input'
import i18n from './i18n'
import VueSimpleAlert from "vue-simple-alert";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import LiquorTree from 'liquor-tree'
import TreeView from '@ll931217/vue-treeview'
import moment from 'moment'
import VueMomentJS from 'vue-momentjs'

Vue.use(TreeView)
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)
router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (!language) {
    language = 'ko'
  }

  i18n.locale = language
  next()
})
import VueCountryCode from "vue-country-code-select";
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(VueCountryCode);
Vue.use(LiquorTree)
 
Vue.use(VueSimpleAlert);
Vue.use(addCommaPlugin) 
Vue.use(VueTelInput)
Vue.use(Loading);
Vue.use(VueMomentJS, moment)

Vue.prototype.$http = axios
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
