<template>
	<div id="header">
		<div class="top_section">
			<!--top_section-->
			<div class="level_box">
				<img src="/assets/img/main_icon.png" class="level_img">	<span class="usernamue">{{name}}<br><span>{{id}}</span><br><span v-if="star != 0">{{star}} STAR</span></span>
			</div>
			<div class="theme_btn">
				<!--테마선택-->
				<div class="language_btn01" style="width:170px"><span class="theme_span">{{ themeName }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
					<div class="dep_01">
						<ul class="dep_02" style="width:170px">
							<li value="theme03"><a href="javascript:;" @click="SetTheme('theme03')">{{$t("dashboard.25")}}</a></li>
							<li value="theme01"><a href="javascript:;" @click="SetTheme('theme01')">{{$t("dashboard.26")}}</a></li>
							<!-- <li value="theme03"><a href="javascript:;" @click="SetTheme('theme02')">{{$t("dashboard.27")}}</a></li> -->
						</ul>
					</div>
				</div>
			</div>
			<div class="language_btn">
				<!--언어선택-->
				<div class="language_btn01"> <span><img :src="`/assets/img/${langImg[langIndex]}`" class="language_img">{{ language }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
					<div class="dep_01">
						<ul class="dep_02">
							<li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
								<a href="javascript:;" @click="SetLocale(i)">
									<img :src="`/assets/img/${langImg[i]}`" class="language_img">{{lang}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!--언어선택-->
		</div>
		<!--top_section end-->
		<div class="m_top_section">
			<!--m_top_section-->
			<div class="m_level">
				<p class="d-flex align-items-center">
					<span class='usernamue d-flex flex-column'>
						<div class="d-flex justify-content-start" v-if="star">
							<i class="fas fa-star" v-for="item in star" :key="item"></i>
						</div>
						<div class="name d-flex align-items-center">
							<i class="fal fa-user"></i>
							<span>{{id}}</span>
						</div>
					</span>
					<!-- <span>{{id}}</span>
					<span class='usernamue' v-if="star != 0">{{star}} STAR</span> -->
					<!-- <img src="/assets/img/main_icon.png" alt="등급" class="m_level_img" style="vertical-align: middle;"> -->
				</p>
			</div>
			<div class="m_logo">
				<router-link :to="`/${$i18n.locale}/dashboard`" class="m_logo">
					<img class="white_logo" src="/assets/img/h_logo_white.png">
					<img class="color_logo" src="/assets/img/h_logo.png">
				</router-link>
			</div>
			
			<div class="m_nav">
				<img src="/assets/img/m_nav.png" alt="모바일네비게이션" class="m_nav menu">
			</div>
			<div id="m_nav">
				<div class="position-fixed container top-0 sidemenu_top">
					<div class="theme_btn">
						<div class="language_btn flex-center-center">
							<img src="/assets/img/moon.svg" @click="SetTheme('theme01')" width="20" style="padding: 2.5px" v-if="themeName == $t('sidebar.50')">
							<img src="/assets/img/sun.svg" @click="SetTheme('theme03')" width="20"  v-if="themeName == $t('sidebar.51')">
						</div>
					</div>
					<div class="language_btn">
						<div class="language_btn01"> <span><img :src="`/assets/img/${langImg[langIndex]}`" class="language_img">{{ language }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
							<div class="dep_01">
								<ul class="dep_02">
									<li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
										<a href="javascript:;" @click="SetLocale(i)">
											<img :src="`/assets/img/${langImg[i]}`" class="language_img">{{lang}}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<i class="close fal fa-times"></i>
				</div>
				<!-- profile_section -->
				<div class="profile_section flex justify-content-between">
					<div class="profile flex align-items-center">
						<div class="bg_img" :style="'background-color: #c2c8d7; border: 1px solid #c2c8d7; background-image:url(/assets/img/profile_basic.svg);'"></div>
						<div class="txt_box">
							<p class="user_id">{{id}}</p>
						</div>
					</div>
					<div class="level">
						<div class="stars">
							<span>
								<i v-for="(item,index) in star" :key="index" class="fas fa-star"></i>
							</span>
							<span >
								<i v-for="(item,index) in 7-star" :key="index" class="fal fa-star"></i>
							</span>
						</div>

						<div class="txt_box">
							<p><span>{{star}}</span> STAR</p>
						</div>
					</div>
				</div>

				<ul class="nav_list">
					<li class="sub-menu">
						<a href="javascript:void(0)" @click="$router.push(`/${$i18n.locale}/dashboard`).catch(() => { closeMenu() })">
							<img :src="'/assets/img/icon07.svg'" alt="" class="menu_icon" width="13">Home
						</a>
					</li>
					<li class="sub-menu" v-for="(item, index) in menu" :key="'depth01'+index">
						<a href="javascript:void(0)" @click="listOpen()">
							<img :src="item.img" alt="" class="menu_icon" width="13">{{item.title}}
							<img src="/assets/img/more2.png" alt="" class="more">
						</a>
						<ul class="tab_menu" style="display:none;">
							<li class="tab_menu2"  @click="closeMenu()" v-for="(child, index) in item.children" :key="'depth02'+index">
								<router-link :to="`/${$i18n.locale}${child.url}`" ><span class="icon_01"></span>{{child.title}}</router-link>
							</li>
						</ul>
					</li>
				</ul>
				
				
				<button class="btn logout" @click="logout()">{{$t("sidebar.99")}}</button>
				<a  class="btn logout guide_btn"  :href="`/assets/guide/ninitherapy_user_guide_${$i18n.locale}.pdf`" download="">{{ $t('guide.100') }}</a>
				<!-- <button class="btn logout" @click="download()">{{ $t('guide.100') }}</button> -->
			</div>
			
			<!-- // #m_nav -->
		</div>
		<!--m_top_section end-->
	</div>
	<!--header end-->
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			theme: this.$store.state.theme,
			themeName:'',
			id : '',
			star:'',
			name :'',
			level :'',
			menu:[
                        {
                            // title: "고객센터",
                            title: this.$t('sidebar.1'),
                            img: '/assets/img/icon01.png',
                            children: [
                                {
                                    title: this.$t('sidebar.2'),
                                    url:'/notice'
                                },
                                {
                                    title: this.$t('sidebar.3'),
                                    url:'/shared'
                                },
                                {
                                    title: this.$t('sidebar.4'),
                                    url:'/inquiry'
                                }
                            ]
                        },
                        {
                            title: this.$t('sidebar.5'),
                            img: '/assets/img/icon02.png',
                            children: [
                                {
                                    title: this.$t('sidebar.6'),
                                    url:'/ReferralTree'
                                },
                                {
                                    title: this.$t('sidebar.7'),
                                    url:'/SupportTree'
                                },
                            ]
                        },
                        {
                            title: this.$t('sidebar.8'),
                            img: '/assets/img/icon03.png',
                            children: [
                                {
                                    title: this.$t('sidebar.9'),
                                    url:'/Membership'
                                },
								{
                                    title: this.$t('sidebar.33'),
                                    url:'/Transfer'
                                }
                            ]
                        },
                        {
                            title: this.$t('sidebar.32'),
                            img: '/assets/img/icon04.png',
                            children: [
                                // {
                                //     title: this.$t('sidebar.11'),
                                //     url:'/Deposit'
                                // },
                                {
                                    title: this.$t('sidebar.30'),
                                    url:'/Withdraw'
                                },
                                {
                                    title: this.$t('sidebar.31'),
                                    url:'/NccAssetsList'
                                }
                            ]
                        },
                        {
                            title: this.$t('sidebar.15'),
                            img: '/assets/img/icon05.png',
                            children: [
                                {
                                    title: this.$t('sidebar.16'),
                                    url:'/SalesList'
                                },
                                {
                                    title: this.$t('sidebar.17'),
                                    url:'/AssetsHistory'
                                },
                                {
                                    title: this.$t('sidebar.18'),
                                    url:'/ReferralHistory'
                                },
                                {
                                    title: this.$t('sidebar.19'),
                                    url:'/SupportHistory'
                                },
                                {
                                    title: this.$t('sidebar.20'),
                                    url:'/MatchHistory'
                                },
                                {
                                    title: this.$t('sidebar.21'),
                                    url:'/StarHistory'
                                },
                                {
                                    title: this.$t('sidebar.22'),
                                    url:'/MemberHistory'
                                },
                                {
                                    title: this.$t('sidebar.23'),
                                    url:'/RollupHistory'
                                },
                            ]
                        },
                        {
                            title: this.$t('sidebar.24'),
                            img: '/assets/img/icon06.png',
                            children: [
                                
                                {
                                    title: this.$t('sidebar.25'),
                                    url:'/profile'
                                },
                                {
                                    title: this.$t('sidebar.26'),
                                    url:'/changePw'
                                },
                                {
                                    title: this.$t('sidebar.27'),
                                    url:'/changePin'
                                },
                                {
                                    title: this.$t('sidebar.28'),
                                    url:'/otp'
                                },
                            ]
                        },
			],

			
			language : this.$t('language.1'),
			langs: [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5"), this.$t("language.6")],
			paramsLang: ['ko', 'en', 'jp', 'cn', 'vn', 'th'],
			langImg: ['korea.png', 'usa.png', 'jpn.png', 'cha.png', 'vtn.png', 'th.jpg'],
			langIndex: 0
		}
	},
	created(){
		this.SetLanguage();
	},
    mounted(){
		
		if(this.$store.state.login == false){
			this.$router.push({path:'/'+this.$i18n.locale+'/login'})
		}
        $(document).ready(function() {
            $(".menu").click(function() {
                $("#m_nav").show();
            });
            $(".close").click(function() {
                $("#m_nav").hide()
            });
		});

		this.GetUserInfo()
		
		this.theme === 'theme01' ? this.themeName = this.$t('sidebar.51') : this.themeName = this.$t('sidebar.50');
	},
	methods:{
		download() {
            try {
                let element = document.createElement('a');
				element.setAttribute('download');
                element.setAttribute('href',`/assets/guide/ninitherapy_user_guide_${this.$i18n.locale}.pdf`);
                element.click();
            } catch(error) {
                console.log(error)
            }
        },
		GetUserInfo(){
				
			const body = {};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/common/GetUserInfo`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.star = body.info.star*1;
							this.id = body.info.id;
							this.name = body.info.name;

						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},
		SetTheme(type){
			this.$store.state.theme = type;

			console.log(type)

			if(type == 'theme03'){
				this.themeName = this.$t("sidebar.50");
				const mem_info = {type};
				this.$store.dispatch('SETTHEMA',{mem_info})
			}
			// else if(type == 'theme02'){
			// 	this.themeName = this.$t("sidebar.52");
			// 	const mem_info = {type};
			// 	this.$store.dispatch('SETTHEMA',{mem_info})
			// }
			else {
				this.themeName = this.$t("sidebar.51");
				const mem_info = {type};
				this.$store.dispatch('SETTHEMA',{mem_info})
			}
		},
		SetLanguage(){
			var lang = this.$route.params.lang;
			if(lang == 'ko') {
				this.language =  this.$t("language.1");
				this.langIndex = 0;
			}else if(lang == 'en') {
				this.language = this.$t("language.2");
				this.langIndex = 1;
			}else if(lang == 'jp') {
				this.language = this.$t("language.3");
				this.langIndex = 2;
			}else if(lang == 'cn') {
				this.language = this.$t("language.4");
				this.langIndex = 3;
			}else if(lang == 'vn') {
				this.language = this.$t("language.5");
				this.langIndex = 4;
			}else if(lang == 'th') {
				this.language = this.$t("language.6");
				this.langIndex = 5;
			}else {
				this.language = this.$t("language.1");
				this.langIndex = 0;
			}
		},
		SetLocale(locale){
			this.$i18n.locale = this.paramsLang[locale];
		
			this.$router.push({
				params: {lang: this.paramsLang[locale]}
			})
			this.langs = [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5"), this.$t("language.6")];
			this.language = this.langs[locale];
			this.langIndex = locale;
			this.$router.go(0);
			
		},
		listOpen(target){
			$(target).next().toggle();
		},
		closeMenu(){
			$("#m_nav").hide()
		},
		logout : function(){
			this.$store.dispatch('SETLOGOUT').then(
                ()=>{
                    this.$router.push({path:'/'+this.$i18n.locale+'/login'});
                }
            )
		},
	}
	
}
</script>

<style scoped>
	.dep_02 li a {font-size: 14px;}

	.theme_btn{
		position: absolute;
		top: 14px;
		left: 20px;
	}

	.theme_span {
		margin: 10px;
	}
	.theme01 .logout.guide_btn{
		color: #fff;
		background-color: #363741;
	}
	.sidemenu_top{
		z-index: 1;
		height: 70px;
		margin-left: -1px;
	}
	.theme01 .sidemenu_top{
		background: #424350;
	}
	.theme02 .sidemenu_top,
	.theme03 .sidemenu_top{
		background: #fff;
	}

</style>