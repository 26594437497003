<template>
  <div class="sign" id="wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Sign',
  components: {
  }
}
</script>
